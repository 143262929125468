<template>
  <div>
    <location />
    <div class="page">
      <tab />
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-2"
          role="tabpanel"
          aria-labelledby="pills-2-tab"
        >
          <div class="wrap-1200">
            <b-form
              @submit="onSubmit"
              class="filter-group col-sm-6 col-md-4 col-lg-3 ms-auto"
            >
              <div class="input-group">
                <!-- <input
                  type="text"
                  value="Status"
                  readonly
                /> -->
                <select
                  class="form-select"
                  aria-label="Select tournament status"
                  name="status"
                  v-model="searchData.status"
                >
                  <option value="0">{{ $t("filter.common.total") }}</option>
                  <option value="1">{{ $t("filter.common.ready") }}</option>
                  <option value="2">{{ $t("filter.common.playing") }}</option>
                  <option value="3">{{ $t("filter.common.completed") }}</option>
                  <option value="4">{{ $t("filter.common.cancel") }}</option>
                </select>
                <button
                  class="btn btn-primary btn-md icon-search"
                  type="submit"
                ></button>
              </div>
            </b-form>

            <table class="table board">
              <thead>
                <tr>
                  <th class="d-lg-table-cell">
                    {{ $t("title.common.tournament") }}
                  </th>
                  <!-- <th class="d-lg-table-cell">{{ $t("title.common.location") }}</th> -->
                  <th class="d-none d-lg-table-cell">
                    {{ $t("title.common.date") }}
                  </th>
                  <th class="d-none d-lg-table-cell">
                    {{ $t("title.membership.entry_ticket") }}
                  </th>
                  <th class="d-none d-lg-table-cell">
                    {{ $t("title.common.no_of_play") }}
                  </th>
                  <th class="d-none d-md-table-cell w-1">
                    {{ $t("title.common.status") }}
                  </th>
                  <th class="w-1 d-lg-table-cell">
                    {{ $t("title.common.leaderboard") }}
                  </th>
                  <th class="th-arrow">&nbsp;</th>
                </tr>
              </thead>
              <tbody v-if="data_yn">
                <template v-for="(item, index) in items">
                  <tr :key="index">
                    <td class="d-lg-table-cell text-start">
                      <router-link
                        :to="{
                          name: 'MyTournamentsView',
                          params: { id: item.tournamentinfo.tournamentno },
                        }"
                        class="d-inline-block pointer"
                      >
                        <span class="fs-12 text-capitalize text-black">{{
                          tournament_type(item.tournamentinfo.type)
                        }}</span>
                        /
                        <span class="fs-12 text-capitalize text-black">{{
                          item.tournamentinfo.tour_rank_type | tour_rank_type
                        }}</span>
                        <br />

                        <span class="more">{{
                          item.tournamentinfo.title
                        }}</span>
                      </router-link>
                    </td>
                    <!-- <td class="d-lg-table-cell">
                      <template v-for="(shop, s_index) in item.tournamentshop">
                        <p :key="s_index">{{ shop.shopname }}</p>
                      </template>
                    </td> -->
                    <td class="d-none d-lg-table-cell w-1">
                      <p
                        class="text-date"
                        v-if="
                          item.tournamentinfo.timezone_startdate
                            .timezone_private
                        "
                      >
                        <!-- 사용자 시간 -->
                        <dfn
                          class="time-zone-mark badge rounded-pill bg-orange"
                          title="Connect time"
                          >CT</dfn
                        >
                        {{
                          item.tournamentinfo.timezone_startdate
                            .timezone_private | dateformat
                        }}
                        ~
                        {{
                          item.tournamentinfo.timezone_enddate.timezone_private
                            | dateformat
                        }}
                      </p>
                      <p
                        class="text-date"
                        v-else-if="
                          item.tournamentinfo.timezone_startdate.timezone_common
                        "
                      >
                        <!-- 서비스 시간 -->
                        <dfn
                          class="time-zone-mark badge rounded-pill bg-green"
                          title="Standard time"
                          >ST</dfn
                        >
                        {{
                          item.tournamentinfo.timezone_startdate.timezone_common
                            | dateformat
                        }}
                        ~
                        {{
                          item.tournamentinfo.timezone_enddate.timezone_common
                            | dateformat
                        }}
                      </p>
                    </td>
                    <td class="d-none d-lg-table-cell">
                      <span
                        class="more"
                        @click="
                          get_playerpaymentlist({
                            tour_pk: item.tournamentinfo.tour_pk,
                          })
                        "
                      >
                        {{ item.tournamentinfo.entry_ticket_cnt | suffix }}
                      </span>
                    </td>
                    <td class="d-none d-lg-table-cell">
                      <router-link
                        :to="{
                          name: 'MyTournamentsAttend',
                          params: { id: item.tournamentinfo.tournamentno },
                        }"
                        class="more text-red"
                      >
                        {{ item.tournamentinfo.playcnt }} /
                        {{
                          item.tournamentinfo.entrylimit | transToValue("∞")
                        }}</router-link
                      >
                    </td>
                    <td class="d-none d-md-table-cell text-nowrap">
                      {{ tournament_status(item.tournamentinfo.status) }}
                    </td>
                    <td class="d-lg-table-cell">
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-xs"
                        @click="
                          $router.push({
                            name: 'MyTournamentsRanking',
                            params: { id: item.tournamentinfo.tournamentno },
                          })
                        "
                      >
                        {{ $t("button.common.view") }}
                      </button>
                    </td>
                    <td class="td-arrow" @click="toggle_tr(index)">
                      <img
                        src="/img/arrow_none_collapsed.png"
                        :class="{ 'btn-toggle-arrow': view_hide === index }"
                      />
                    </td>
                  </tr>
                  <tr
                    class="d-lg-none"
                    :class="{ show: view_hide === index }"
                    :key="index + 'r'"
                    v-if="view_hide === index"
                  >
                    <td colspan="5">
                      <div class="toggle-content">
                        <div class="row">
                          <div class="col-4">{{ $t("title.common.date") }}</div>
                          <div class="col-8">
                            <p
                              class="text-date"
                              v-if="
                                item.tournamentinfo.timezone_startdate
                                  .timezone_private
                              "
                            >
                              <!-- 사용자 시간 -->
                              <dfn
                                class="time-zone-mark badge rounded-pill bg-orange"
                                title="Connect time"
                                >CT</dfn
                              >
                              {{
                                item.tournamentinfo.timezone_startdate
                                  .timezone_private | dateformat
                              }}
                              ~
                              {{
                                item.tournamentinfo.timezone_enddate
                                  .timezone_private | dateformat
                              }}
                            </p>
                            <p
                              class="text-date"
                              v-else-if="
                                item.tournamentinfo.timezone_startdate
                                  .timezone_common
                              "
                            >
                              <!-- 서비스 시간 -->
                              <dfn
                                class="time-zone-mark badge rounded-pill bg-green"
                                title="Standard time"
                                >ST</dfn
                              >
                              {{
                                item.tournamentinfo.timezone_startdate
                                  .timezone_common | dateformat
                              }}
                              ~
                              {{
                                item.tournamentinfo.timezone_enddate
                                  .timezone_common | dateformat
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            {{ $t("title.membership.entry_ticket") }}
                          </div>
                          <div class="col-8">
                            <span
                              class="more"
                              @click="
                                get_playerpaymentlist({
                                  tour_pk: item.tournamentinfo.tour_pk,
                                })
                              "
                            >
                              {{
                                item.tournamentinfo.entry_ticket_cnt | suffix
                              }}
                            </span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            {{ $t("title.common.no_of_play") }}
                          </div>
                          <div class="col-8">
                            <router-link
                              :to="{
                                name: 'MyTournamentsAttend',
                                params: {
                                  id: item.tournamentinfo.tournamentno,
                                },
                              }"
                              class="more text-red"
                              >{{ item.tournamentinfo.playcnt }} /
                              {{
                                item.tournamentinfo.entrylimit
                                  | transToValue("∞")
                              }}</router-link
                            >
                          </div>
                        </div>
                        <div class="row d-md-none">
                          <div class="col-4">
                            {{ $t("title.common.status") }}
                          </div>
                          <div class="col-8">
                            {{ tournament_status(item.tournamentinfo.status) }}
                          </div>
                        </div>
                        <!-- <div class="row">
                          <div class="col-4">{{ $t("title.common.leaderboard") }}</div>
                          <div class="col-8">
                            <button
                              type="button"
                              class="btn btn-outline-primary btn-xs"
                              @click="
                                $router.push({
                                  name: 'MyTournamentsRanking',
                                  params: {
                                    id: item.tournamentinfo.tournamentno,
                                  },
                                })
                              "
                            >
                              {{ $t("button.common.view") }}
                            </button>
                          </div>
                        </div> -->
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="5" class="text-center">
                    {{ $t("text.common.no_data") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="page_total"
            v-model="searchData.pageno"
            use-router
            aria-label="Page navigation"
            v-if="page_total > 1"
            first-class="prev"
            prev-class="prev"
            next-class="next"
            last-class="next"
            hide-ellipsis
            limit="10"
          >
            <template #first-text>
              <i class="material-icons">keyboard_double_arrow_left</i>
            </template>
            <template #prev-text>
              <i class="material-icons">navigate_before</i>
            </template>
            <template #next-text>
              <i class="material-icons">navigate_next</i>
            </template>
            <template #last-text>
              <i class="material-icons">keyboard_double_arrow_right</i>
            </template>
            <template #page="{ page, active }">
              <a v-if="active">{{ page }}</a>
              <a v-else>{{ page }}</a>
            </template>
          </b-pagination-nav>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-pay-history"
      ref="modal-pay-history"
      size="xl"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header>
        <h5 class="modal-title" id="exampleModalLabel">Pay History</h5>
        <b-button
          variant="close"
          :aria-label="$t('button.common.close')"
          @click="$refs['modal-pay-history'].hide()"
        ></b-button>
      </template>

      <MemberInfo
        :nickname="playerpaymentinfo.nickname"
        :playerno="playerpaymentinfo.player_pk"
        :member_img="playerpaymentinfo.member_img"
        :badge_img="playerpaymentinfo.badge_img"
      />
      <br />
      {{ $t("text.common.entry_fee") }} : 0 /
      {{ $t("title.common.entry_ticket_cnt") }} :
      {{ playerpaymentinfo.entry_ticket_cnt }} /
      {{ $t("title.common.used_ticket_cnt") }} :
      {{ playerpaymentinfo.used_ticket_cnt }}

      <table class="table board table-sm">
        <thead>
          <tr>
            <th class="d-lg-table-cell">
              {{ $t("title.common.number") }}
            </th>
            <th class="d-none d-lg-table-cell">
              {{ $t("title.membership.payment_no") }}
            </th>
            <th class="col-auto d-lg-table-cell">
              {{ $t("title.common.title") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.membership.payment_date") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.membership.used_date") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="playerpaymentlist.length > 0">
          <template v-for="(item, index) in playerpaymentlist">
            <tr :key="index">
              <td class="d-lg-table-cell">
                {{ item.no }}
              </td>
              <td class="d-none d-lg-table-cell">
                {{ item.tor_key }}
              </td>
              <td class="d-lg-table-cell text-date">
                {{ item.title }}
              </td>
              <td class="d-none d-lg-table-cell text-date">
                {{ item.timezone_regdate.timezone_private | dateformat }}
              </td>
              <td class="d-none d-lg-table-cell text-date">
                <template v-if="item.tod_status == '1'">
                  {{ item.timezone_used_regdate.timezone_private | dateformat }}
                </template>
                <template v-else-if="item.tod_status == '2'">
                  {{
                    item.timezone_refund_regdate.timezone_private | dateformat
                  }}
                  <br />(refund)
                </template>
              </td>

              <td class="td-arrow" @click="toggle_tr3(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide3 === index }"
                />
              </td>
            </tr>
            <tr
              class="d-lg-none"
              :class="{ show: view_hide3 === index }"
              :key="index + 'r'"
              v-if="view_hide3 === index"
            >
              <td colspan="4">
                <div class="toggle-content">
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.membership.payment_no") }}
                    </div>
                    <div class="col-8">{{ item.tor_key }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.membership.payment_date") }}
                    </div>
                    <div class="col-8">
                      {{ item.timezone_regdate.timezone_private | dateformat }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.membership.used_date") }}
                    </div>
                    <div class="col-8">
                      <template v-if="item.tod_status == '1'">
                        {{
                          item.timezone_used_regdate.timezone_private
                            | dateformat
                        }}
                      </template>
                      <template v-else-if="item.tod_status == '2'">
                        {{
                          item.timezone_refund_regdate.timezone_private
                            | dateformat
                        }}
                        <br />(refund)
                      </template>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">
              {{ $t("text.common.no_data") }}
            </td>
          </tr>
        </tbody>
      </table>

      <template #modal-footer="{ cancel }">
        <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.common.cancel')"
          @click="cancel()"
        >
          {{ $t("button.common.cancel") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import ax from "@/api/membership";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "MyTournaments",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        status:
          this.$route.query.status ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].status) ||
          0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
      view_hide3: null,
      playerpaymentlist: [],
      playerpaymentinfo: {},
    };
  },
  methods: {
    get_playerpaymentlist(param) {
      ax.get_playerpaymentlist(param, (flag, data) => {
        if (flag) {
          this.playerpaymentlist = data.list;
          this.playerpaymentinfo = data.playerinfo;

          this.$refs["modal-pay-history"].show();
        } else {
          alert(data.message);
        }
      });
    },
    get_membertournamentlist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_membertournamentlist(this.searchData, (flag, data, code = "") => {
        if (flag) {
          if (data.tournamentlistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.tournamentlistcnt;
          this.page_total = Math.ceil(data.tournamentlistcnt / this.rows);
          this.items = data.tournamentlist;
        } else {
          this.data_yn = false;
          this.page_total = 0;
          if (code == "300") {
            this.$notify({
              group: "alert",
              type: "warn",
              text: data,
              duration: 5000,
              speed: 1000,
            });
          }
          // alert(data);
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            status: this.searchData.status,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },

    toggle_tr3(index) {
      if (this.view_hide3 === index) this.view_hide3 = null;
      else this.view_hide3 = index;
    },
  },
  created() {
    this.get_membertournamentlist();
  },
  watch: {
    "searchData.status": function () {
      this.searchData.pageno = 1;
      this.get_membertournamentlist();
    },
    "searchData.pageno": function () {
      this.get_membertournamentlist();
    },
  },
  mixins: [myMixin],
};
</script>
